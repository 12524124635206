import React, { useState, useCallback, useContext, useEffect } from "react";
import { getparams, post, trash } from "./axios";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import copy from "copy-to-clipboard";
import { Context } from "./context";
import AdminHeader from "./components/adminHeader";

import { Calendar } from "primereact/calendar";
import Loader from "./components/loader";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Link } from "react-router-dom";

const DeliveredList = () => {
  const { state } = useContext(Context);
  const [data, setData] = useState({
    startdate: moment()._d,
    enddate: moment()._d,
    active: "delivered",
    cost_date: null,
    track: "",
    phone: "",
    payment: "",
    loading: false,
    list: [],
    groupList: [],
    cash: 0,
    account: 0,
    card: 0,
    total: 0,
  });
  const [paid_type, setPaid_type] = useState("all");

  const handleTrash = (id) => {
    confirmDialog({
      headerClassName: "p-2 text-center",
      contentClassName: "p-0",
      footerClassName: "p-2",
      header: "Ачааны мэдээлэл устах гэж байна. Устгахдаа итгэлтэй байна уу?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Тийм",
      rejectLabel: "Үгүй",
      accept: () => {
        if (id) {
          setData((prev) => ({
            ...prev,
            loading: true,
          }));
          trash(`/cargo/delete/${id}`)
            .then((res) => {
              if (res.success) {
                toast.success(res.data);
                handleSearch();
              } else {
                toast.warn(res.error);
              }
            })
            .catch((err) => {
              toast.warn(err.message);
            })
            .finally(() => {
              setData((prev) => ({
                ...prev,
                loading: false,
              }));
            });
        } else {
          toast.warn("ID байхгүй байна.");
        }
      },
      reject: () => {},
    });
  };

  const UnPaidAndUnReceived = (list) => {
    confirmDialog({
      headerClassName: "p-2 text-center",
      contentClassName: "p-0",
      footerClassName: "p-2",
      header:
        "Ачааны мэдээлэл буцаж Эрдэнэтэд ирсэн төлөвт орох гэж байна. Та итгэлтэй байна уу?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Тийм",
      rejectLabel: "Үгүй",
      accept: () => {
        if (list?.length > 0) {
          setData((prev) => ({
            ...prev,
            loading: true,
          }));
          post("/cargo/UnPaidAndUnReceived", { data: list })
            .then((res) => {
              if (res.success) {
                toast.success(res.data);
                handleSearch();
              } else {
                toast.warn(res?.error);
              }
            })
            .catch((err) => {
              toast.warn(err?.message);
            })
            .finally(() => {
              setData((prev) => ({
                ...prev,
                loading: false,
              }));
            });
        } else {
          toast.warn("Жагсаалт хоосон байна.");
        }
      },
      reject: () => {},
    });
  };

  const handleSearch = useCallback(() => {
    setData((prev) => ({
      ...prev,
      loading: true,
    }));
    var params = {};
    if (data.active === "arrived") {
      params.active = 1;
    } else if (data.active === "delivered") {
      params.active = 0;
    } else if (data.active === "all" && !data.cost_date) {
      if (!data.track && !data.phone) {
        return setData((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }

    if (data.startdate)
      params.startdate = moment(data.startdate).format("YYYY-MM-DD");
    if (data.enddate)
      params.enddate = moment(data.enddate).format("YYYY-MM-DD") + " 24:00:00";
    if (data.cost_date)
      params.cost_date = moment(data.cost_date).format("YYYY-MM-DD");
    if (data.payment) params.payment = data.payment;
    if (data.track) params.track = data.track;
    if (data.phone) params.phone = data.phone;
    getparams("/cargo/activelist", params)
      .then((res) => {
        if (res.success) {
          setData((prev) => ({
            ...prev,
            list: res?.data,
          }));
        } else {
          toast.warn(res.error);
        }
      })
      .catch((err) => {
        toast.warn(err.message);
      })
      .finally(() => {
        setData((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  }, [
    data.active,
    data.enddate,
    data.payment,
    data.phone,
    data.startdate,
    data.track,
    data.cost_date,
  ]);

  useEffect(() => {
    var result = data?.list;

    if (paid_type !== "all") {
      result = _.filter(result, (el) => {
        if (paid_type === "cash") {
          return el?.paid_type === "cash";
        } else if (paid_type === "card") {
          return el?.paid_type === "card";
        } else if (paid_type === "account") {
          return el?.paid_type === "account";
        }
      });
    }

    const groupedData = _.sortBy(
      Object.entries(_.groupBy(result, "delivered")),
      [
        (o) => {
          if (o?.length > 0) {
            if (o[1]?.length > 0) {
              return o[1][0].delivered;
            }
          }
        },
      ]
    );

    setData((prev) => ({
      ...prev,
      groupList: groupedData,
      card: _.sumBy(_.filter(data?.list, { paid_type: "card" }), (el) => {
        return parseInt(el?.payment);
      }),
      cash: _.sumBy(_.filter(data?.list, { paid_type: "cash" }), (el) => {
        return parseInt(el?.payment);
      }),
      account: _.sumBy(_.filter(data?.list, { paid_type: "account" }), (el) => {
        return parseInt(el?.payment);
      }),
      total: _.sumBy(data?.list, (el) => {
        return parseInt(el?.payment);
      }),
    }));
  }, [data?.list, paid_type]);

  const now_time = moment().format("YYYY-MM-DD HH:mm");

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  return (
    <div className="container mx-auto">
      <ConfirmDialog />
      {data?.loading && <Loader />}
      {/* header section */}
      <div className="sticky top-0 bg-white z-10">
        <AdminHeader />
        {state?.is_sa && (
          <div className="flex items-center justify-center border-b">
            <Calendar
              className="w-full border-none p-2 text-sm"
              placeholder="Ашиг харах огноо"
              showButtonBar
              value={data?.cost_date}
              onChange={(e) => {
                if (e.value) {
                  setData((prev) => ({
                    ...prev,
                    cost_date: e.value,
                    startdate: null,
                    enddate: null,
                    active: "all",
                  }));
                } else {
                  setData((prev) => ({
                    ...prev,
                    cost_date: e.value,
                    startdate: moment()._d,
                    enddate: moment()._d,
                    active: "delivered",
                  }));
                }
              }}
            />
          </div>
        )}

        <div className="flex items-center justify-center">
          <Calendar
            className="w-full border-none px-2 text-sm"
            placeholder="Эхлэх огноо"
            showButtonBar
            value={data?.startdate}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                startdate: e.value,
              }))
            }
          />
          <Calendar
            className="w-full border-none py-2 px-2 text-sm"
            placeholder="Дуусах огноо"
            showButtonBar
            value={data?.enddate}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                enddate: e.value,
              }))
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <input
            className="bg-white border border-gray-100 text-gray-900 block p-2 w-full outline-none"
            type="number"
            placeholder="Мөнгөн дүн"
            value={data?.payment}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                payment: e.target.value,
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <select
            className="bg-white text-gray-900 block p-2 w-full outline-none"
            value={data?.active}
            onChange={(e) => {
              if (e.target.value === "all") {
                setData((prev) => ({
                  ...prev,
                  active: e.target.value,
                  startdate: null,
                  enddate: null,
                }));
              } else if (e.target.value === "arrived") {
                setData((prev) => ({
                  ...prev,
                  active: "arrived",
                }));
              } else if (e.target.value === "delivered") {
                setData((prev) => ({
                  ...prev,
                  active: "delivered",
                }));
              } else {
                setData((prev) => ({
                  ...prev,
                  startdate: moment()._d,
                  enddate: moment()._d,
                  active: "delivered",
                }));
              }
            }}
          >
            <option value="all">Бүгд</option>
            <option value="arrived">Ирсэн</option>
            <option value="delivered">Өгсөн</option>
          </select>
        </div>
        <div className="flex items-center justify-center">
          <input
            className="bg-white border border-gray-100 text-gray-900 block p-2 w-full outline-none"
            type="text"
            placeholder="Кодоор хайх"
            value={data?.track}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                track: e.target.value,
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <input
            className="bg-white border border-gray-100 text-gray-900 block p-2 w-full outline-none"
            type="number"
            placeholder="Утсаар хайх"
            value={data?.phone}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                phone: e.target.value,
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
        </div>
        <select
          className="bg-white text-gray-900 block p-2 w-full outline-none"
          value={data?.paid_type}
          onChange={(e) => {
            setPaid_type(e.target.value);
          }}
        >
          <option value="all">Бүгд</option>
          <option value="account">Данс</option>
          <option value="card">Карт</option>
          <option value="cash">Бэлэн</option>
        </select>
        <div
          className="px-4 py-1 bg-green-500 text-center text-white font-medium cursor-pointer"
          onClick={() => {
            handleSearch();
          }}
        >
          Хайх
        </div>
      </div>

      {/* header section */}
      <div className="w-full overflow-auto text-xs md:text-base text-gray-100">
        <div>
          <div>Данс: {data?.account?.toLocaleString()}₮</div>
          <div>Карт: {data?.card?.toLocaleString()}₮</div>
          <div>Бэлэн: {data?.cash?.toLocaleString()}₮</div>
          <div>Нийт дүн: {data?.total?.toLocaleString()}₮</div>
          <div>Нийт тоо： {data?.list.length}ш</div>
          <div>Утасаар： {data?.groupList.length}ш</div>
        </div>

        <table className="table-auto w-full">
          <thead>
            <tr>
              <th>Утас</th>
              <th>Утас</th>
              <th>Код</th>
              <th>Нэгж үнэ</th>
              <th>Бүртгэсэн</th>
              <th>Ирсэн</th>
              <th>Өгсөн</th>
              <th>Хоног</th>
              {state?.is_sa && <th>#</th>}
            </tr>
          </thead>
          <tbody>
            {_.map(data.groupList, ([date, items], index) => {
              var uniq_delivered_time =
                _.uniqBy(items, "delivered_time").length === 1;

              const totalPayment = _.sumBy(
                items,
                (item) => parseInt(item?.payment) || 0
              );

              return (
                <React.Fragment key={index}>
                  <tr>
                    <td className="text-left" rowSpan={items.length + 1}>
                      <div className="flex flex-col items-center gap-1">
                        <div className="font-medium">
                          <div>
                            {_.map(
                              _.uniqBy(
                                _.orderBy(items, ["phone"], ["desc"]),
                                "phone"
                              ),
                              (p) => {
                                var f_data = _.filter(
                                  items,
                                  (i) => i.phone === p.phone
                                );
                                const p_total = _.sumBy(
                                  f_data,
                                  (item) => parseInt(item?.payment) || 0
                                );

                                return (
                                  <div
                                    key={p.phone}
                                    className="text-white"
                                    onClick={() => copy(p.phone.trim())}
                                  >
                                    {p?.phone ? (
                                      <div className="text-gray-300">
                                        {p?.phone}
                                      </div>
                                    ) : (
                                      <div className="text-red-700">
                                        дугааргүй
                                      </div>
                                    )}
                                    <div
                                      className={`${
                                        p?.phone
                                          ? "text-gray-300"
                                          : "text-red-700"
                                      } ${
                                        totalPayment === p_total &&
                                        f_data?.length === items?.length
                                          ? "hidden"
                                          : ""
                                      }`}
                                    >
                                      {f_data?.length}ш
                                    </div>
                                    <div
                                      className={`${
                                        p?.phone
                                          ? "text-gray-300"
                                          : "text-red-700"
                                      } ${
                                        totalPayment === p_total &&
                                        f_data?.length === items?.length
                                          ? "hidden"
                                          : ""
                                      }`}
                                    >
                                      {p_total.toLocaleString()}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="border-t text-green-600">{date}</div>
                          <div className="text-green-600">{items?.length}ш</div>
                          <div className="border-b text-green-600">
                            {totalPayment.toLocaleString()}
                          </div>
                        </div>
                        {state?.is_sa && (
                          <div className="flex items-center justify-center gap-1">
                            <div
                              className="p-1 rounded bg-red-600 text-white cursor-pointer text-center font-thin"
                              onClick={() => {
                                UnPaidAndUnReceived(items);
                              }}
                            >
                              Буцаах
                            </div>
                          </div>
                        )}
                        {!state?.is_sa &&
                          uniq_delivered_time &&
                          moment(items[0]?.delivered)
                            .add(5, "minutes")
                            .format("YYYY-MM-DD HH:mm") > now_time &&
                          items[0].phone && (
                            <div className="flex items-center justify-center gap-1">
                              <div
                                className="p-1 rounded bg-red-600 text-white cursor-pointer text-center font-thin"
                                onClick={() => {
                                  UnPaidAndUnReceived(items);
                                }}
                              >
                                Буцаах
                              </div>
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                  {_.map(
                    _.orderBy(items, ["phone"], ["desc"]),
                    (item, index2) => (
                      <tr key={`${index}-${index2}`}>
                        <td
                          className="text-left"
                          onClick={() => {
                            toast.success("Трак код хуулагдлаа");
                            copy(item?.track.trim());
                          }}
                        >
                          {!state?.is_sa &&
                            moment(item?.delivered)
                              .add(5, "minutes")
                              .format("YYYY-MM-DD HH:mm") > now_time &&
                            !item?.phone && (
                              <div className="flex items-center justify-center gap-1">
                                <div
                                  className="p-1 rounded bg-red-600 text-white cursor-pointer text-center font-thin"
                                  onClick={() => {
                                    UnPaidAndUnReceived(items);
                                  }}
                                >
                                  Буцаах
                                </div>
                              </div>
                            )}
                          <span>{item?.track}</span>

                          {item?.phone ? (
                            <span className="text-xs pl-2 text-gray-300">
                              {item?.phone}
                            </span>
                          ) : (
                            <span className="text-xs pl-2 text-red-700">
                              дугааргүй
                            </span>
                          )}
                        </td>
                        <td className="text-left">
                          {parseInt(item?.payment).toLocaleString()}
                        </td>
                        <td className="text-left whitespace-nowrap">
                          {moment(item?.registered).format("YYYY-MM-DD")}
                        </td>
                        <td className="text-left whitespace-nowrap">
                          {moment(item?.arrived).format("YYYY-MM-DD HH:mm")}
                        </td>
                        <td className="text-left whitespace-nowrap">
                          {moment(item?.delivered).format("YYYY-MM-DD HH:mm")}
                        </td>
                        <td>
                          {item?.arrived &&
                            item?.registered &&
                            moment(item?.arrived).diff(
                              moment(item?.registered),
                              "days"
                            )}
                        </td>
                        {state?.is_sa && (
                          <td>
                            <div className="flex items-center justify-center gap-2">
                              <span
                                className="p-1 bg-red-600 text-white rounded cursor-pointer"
                                onClick={async () => {
                                  handleTrash(item?.id);
                                }}
                              >
                                Устгах
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    )
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(DeliveredList);
