import React, { useState, useRef, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { get, post } from "./axios";

import CheckModal from "./components/checkModal";
import { ClearIcon, PasteIcon, SearchIcon } from "./components/Icons";
import Loader from "./components/loader";
import { Dialog } from "primereact/dialog";
import { Context } from "./context";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import HomeModals from "./components/HomeModals";
import _ from "lodash";

const config = {
  apiKey: "AIzaSyAF0vldKiqcTqKQFiiBqDdGZIn4PdCbeiw",
  authDomain: "kargo-a96b0.firebaseapp.com",
  projectId: "kargo-a96b0",
  storageBucket: "kargo-a96b0.appspot.com",
  messagingSenderId: "1050530103809",
  appId: "1:1050530103809:web:82229e986377dc51d7f600",
};

const app = initializeApp(config);
const defaultFirestore = getFirestore(app);
const fRequestCollection = collection(defaultFirestore, "fRequest");

const Home = () => {
  const { state } = useContext(Context);
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [user, setUser] = useState({});
  const [modal, setModal] = useState(false);
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [notif_modal, setNotif_modal] = useState(false);

  useEffect(() => {
    if (modal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [modal]);

  const Ub = async (list) => {
    await post("/cargo/ub", { data: list });
  };

  const fetchRequestsByPhoneOrCode = async (isPhone, text) => {
    let db = query(fRequestCollection, where("status", "==", "1"));

    const querySnapshot = await getDocs(db);
    var list = querySnapshot.docs.map((doc) => doc.data());

    return _.filter(list, function (o) {
      if (isPhone) {
        return _.includes(o?.phone.trim(), text?.trim());
      } else {
        return _.includes(o?.code.trim(), text?.trim());
      }
    });
  };

  const CheckUran = async (text) => {
    const isPhone = /^\d{8}$/.test(text);

    try {
      let list = await fetchRequestsByPhoneOrCode(isPhone, text);

      const additionalRequests = await Promise.all(
        list
          .filter((el) => el?.phone)
          .map((el) => fetchRequestsByPhoneOrCode(true, el.phone))
      );

      list = [...list, ...additionalRequests.flat()];

      if (list.length > 0) {
        await Ub(list);
      }
    } catch (error) {
      console.error("Error in CheckUran:", error);
      throw error;
    }
  };

  const Call = async (text) => {
    if (text) {
      try {
        setLoading(true);
        let rm_space_text = text.replace(/\s+/g, "");
        var res = await get(`/cargo/check/${rm_space_text}`);
        if (res.success) {
          setValue(rm_space_text);
          setData(res.data);
          setUser(res?.user);
          setModal(true);
        } else {
          toast.warn(res.data);
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
      if (state.userId !== 46) {
        await CheckUran(text);
      }
    }
  };

  const search = async (text) => {
    if (value) {
      await Call(value?.trim());
    } else if (text) {
      await Call(text?.trim());
    }
  };

  return (
    <div className="w-full md:w-[700px] mx-auto h-full pt-16">
      {loading && <Loader />}
      {/* modal */}
      <CheckModal
        data={data}
        value={value}
        modal={modal}
        setModal={setModal}
        Call={Call}
        user={user}
      />
      <Dialog
        header="Мэдэгдэл"
        visible={notif_modal}
        onHide={() => {
          if (!notif_modal) return;
          setNotif_modal(false);
        }}
      >
        <p className="m-0">
          Эрхэм харилцагчдадаа энэ өдрийн мэнд хүргэe. Манай карго 7сарын 10наас
          7сарын 22н хүртэл амарна. Ачаагаа авч амжаагүй хүмүүс өнөөдөр буюу
          7сарын9нд өө яаралтай аваарай баярлалаа😊
        </p>
      </Dialog>
      {/* modal */}
      <img
        src="./cover_web.png"
        alt="erdenet cargo cover"
        className="object-cover"
      />
      <h1 className="text-3xl px-4 mt-2 text-white font-semibold uppercase">
        Ачаа шалгах
      </h1>
      <div className="w-full flex items-center justify-center mt-2 px-4 select-none">
        <div className="relative w-full">
          <input
            ref={inputRef}
            type="text"
            className="bg-white border border-white text-gray-900 rounded-l block p-2.5 w-full outline-none"
            placeholder="Утас эсвэл Тээврийн код оруулна уу"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                search();
              }
            }}
          />
          {value ? (
            <div
              className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
              onClick={() => {
                setValue("");
                inputRef.current.focus();
              }}
            >
              <ClearIcon />
            </div>
          ) : (
            <div
              className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer bg-white`}
              onClick={() => {
                navigator.clipboard
                  .readText()
                  .then((text) => {
                    search(text?.trim());
                  })
                  .catch(() => {
                    toast.info("Хуулсан текст байхгүй байна.");
                  });
              }}
            >
              <PasteIcon />
            </div>
          )}
        </div>
        <div
          className="h-[46px] w-[46px] flex items-center justify-center rounded-r border-l bg-white text-[#F55B00] active:bg-[#F55B00] active:text-white cursor-pointer"
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </div>
      </div>
      <HomeModals />
    </div>
  );
};

export default Home;
